import React from 'react'
import Helmet from 'react-helmet'
import Page from '../templates/page'
import Post from '../templates/post'

class Preview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: '',
            location: {
                pathname: '/'
            },
            pageContext: {
                prev: { title: 'Previous post title' },
                next: { title: 'Next post title' }
            },
            type: '',
            sourceUrl: 'https://rounded-vintage-mechanic.glitch.me'
        }
    }

    componentDidMount() {
        let urlParams = window.location.search.replace('?', '').split('=')
        let paramType = urlParams[0]
        let paramSlug = urlParams[1]

        let getPreviewData = (type, slug) => {
            let ghostType = 'ghost' + type.replace(/^\w/, c => c.toUpperCase())
            let fetchUrl = this.state.sourceUrl

            if (type && slug) {
                fetchUrl = `${this.state.sourceUrl}/${type}/${slug}`
            }

            fetch(fetchUrl, {
                method: 'GET'
            })
            .then((res) => {
                let responce = res.json()

                if (!res.ok) {
                   return responce.then(Promise.reject.bind(Promise))
                }

                return responce
            })
            .then(res => this.setState({ data: { ...this.state.data, [ghostType]: res }, type: type }))
            .catch(err => this.setState({ error: err }))
        }

        getPreviewData(paramType, paramSlug)
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                {this.state.type === 'page' && <Page data={this.state.data} location={this.state.location} />}
                {this.state.type === 'post' && <Post data={this.state.data} location={this.state.location} pageContext={this.state.pageContext} />}
                {this.state.error && <h2>{this.state.error.context}</h2>}
            </>
        )
    }
}

export default Preview